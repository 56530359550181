import { getAnalytics, isSupported, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import mixpanel from 'mixpanel-browser';

const firebaseConfig = {
  apiKey: 'AIzaSyBYw7VOypvQ8R-As90d7s4s48bnaOH8lgQ',
  authDomain: 'venture-builder-4.firebaseapp.com',
  projectId: 'venture-builder-4',
  storageBucket: 'venture-builder-4.appspot.com',
  messagingSenderId: '215362720446',
  appId: '1:215362720446:web:f55482e5225f99701804ef',
  measurementId: 'G-DJ4BJHKK74',
};

const firebaseInit = async () => {
  if (await isSupported()) {
    const app = initializeApp(firebaseConfig);
    return getAnalytics(app);
  }

  return getAnalytics();
};

const getScreenClass = (url: string) => {
  const isCompanyDetail = url.match(/\/(\w|-)+\//g);

  switch (url) {
    case '/':
      return 'Home';

    case '/about':
      return 'About Us';

    case '/recently-added':
      return 'Recently Added';

    case '/popular':
      return 'Popular';

    default:
      if (isCompanyDetail) return 'Company Detail';

      return url;
  }
};

const logScreenViewEvent = async (url: string) => {
  const pageName = getScreenClass(url);
  // Fireabse Analytic
  try {
    const analytics = await firebaseInit();
    if (typeof window !== 'undefined') {
      logEvent(analytics, 'screen_view', {
        firebase_screen: url,
        firebase_screen_class: pageName,
      });
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}

  try {
    mixpanel.track('page_viewed', {
      url,
      title: pageName,
    });
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

const logCustomEvent = async (eventName: string, params?: any) => {
  try {
    const analytics = await firebaseInit();
    if (typeof window !== 'undefined') {
      logEvent(analytics, eventName, params);
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}

  try {
    mixpanel.track(eventName, params);
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

export { getScreenClass, logCustomEvent, logScreenViewEvent };
