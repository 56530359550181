import * as Sentry from '@sentry/nextjs';

import { APP_ENV } from '@/utils/const';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const DEBUG_MODE = process.env.NEXT_PUBLIC_DEBUG_MODE || APP_ENV.DEV;

Sentry.init({
  dsn: SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: DEBUG_MODE,
});
