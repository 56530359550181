import '../styles/global.css';

import mixpanel from 'mixpanel-browser';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

import { logScreenViewEvent } from '@/utils/analytic';
import { isLive } from '@/utils/helper';

const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter();

  useEffect(() => {
    const logEvent = async (url: string) => {
      logScreenViewEvent(url);
    };

    if (isLive()) {
      mixpanel.init(process.env.NEXT_PUBLIC_MIX_PANEL ?? '', {
        debug: !isLive(),
      });
    }

    if (isLive()) {
      const hotjarId = process.env.NEXT_PUBLIC_HOTJAR_ID ?? '';
      hotjar.initialize(parseInt(hotjarId, 10), 6);

      window.addEventListener('unload', () =>
        navigator.sendBeacon(window.location.pathname)
      );
    }

    routers.events.on('routeChangeComplete', logEvent);
    logEvent(window.location.pathname);

    return () => {
      routers.events.off('routeChangeComplete', logEvent);
    };
  }, []);

  return <Component {...pageProps} />;
};

export default MyApp;
