export const COUNTIES = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua And Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas The',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Republic Of The Congo',
  'Democratic Republic Of The Congo',
  'Cook Islands',
  'Costa Rica',
  "Cote D''Ivoire (Ivory Coast)",
  'Croatia (Hrvatska)',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'External Territories of Australia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji Islands',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia The',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey and Alderney',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard and McDonald Islands',
  'Honduras',
  'Hong Kong S.A.R.',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea North',
  'Korea South',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau S.A.R.',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Man (Isle of)',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands Antilles',
  'Netherlands The',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territory Occupied',
  'Panama',
  'Papua new Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn Island',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Helena',
  'Saint Kitts And Nevis',
  'Saint Lucia',
  'Saint Pierre and Miquelon',
  'Saint Vincent And The Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Smaller Territories of the UK',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard And Jan Mayen Islands',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad And Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks And Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City State (Holy See)',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (British)',
  'Virgin Islands (US)',
  'Wallis And Futuna Islands',
  'Western Sahara',
  'Yemen',
  'Yugoslavia',
  'Zambia',
  'Zimbabwe',
  'N/A',
];

export const INDUSTRIES = [
  {
    id: 1,
    name: 'Agriculture and Farming',
    slug: 'agriculture-and-farming',
  },
  {
    id: 2,
    name: 'Artificial Intelligence',
    slug: 'artificial-intelligence',
  },
  {
    id: 3,
    name: 'Commerce and Marketplaces',
    slug: 'commerce-and-marketplaces',
  },
  {
    id: 4,
    name: 'Energy and Natural Resources',
    slug: 'energy-and-natural-resources',
  },
  {
    id: 5,
    name: 'Environment and Climate Tech',
    slug: 'environment-and-climate-tech',
  },
  {
    id: 6,
    name: 'Financial Services and Insurance',
    slug: 'financial-services-and-insurance',
  },
  {
    id: 7,
    name: 'Hardware',
    slug: 'hardware',
  },
  {
    id: 8,
    name: 'Logistics and Transportation',
    slug: 'logistics-and-transportation',
  },
  {
    id: 9,
    name: 'Sales, Marketing and Advertising',
    slug: 'sales-marketing-and-advertising',
  },
  {
    id: 10,
    name: 'Software, Cloud and Apps',
    slug: 'software-cloud-and-apps',
  },
  {
    id: 11,
    name: 'Travel, Hospitality and Food & Beverages',
    slug: 'travel-hospitality-and-food-and-beverages',
  },
  {
    id: 12,
    name: 'Gaming and Sports',
    slug: 'gaming-and-sports',
  },
  {
    id: 13,
    name: 'Manufacturing',
    slug: 'manufacturing',
  },
  {
    id: 14,
    name: 'Messaging and Telecommunications',
    slug: 'messaging-and-telecommunications',
  },
  {
    id: 15,
    name: 'Professional Services',
    slug: 'professional-services',
  },
  {
    id: 16,
    name: 'Science and Engineering',
    slug: 'science-and-engineering',
  },
  {
    id: 17,
    name: 'AR,VR and Video',
    slug: 'ar-vr-and-video',
  },
  {
    id: 18,
    name: 'Biotechnology',
    slug: 'biotechnology',
  },
  {
    id: 19,
    name: 'Blockchain, DLT and Crypto',
    slug: 'blockchain-dlt-and-crypto',
  },
  {
    id: 20,
    name: 'Education',
    slug: 'education',
  },
  {
    id: 21,
    name: 'Government and Military',
    slug: 'government-and-military',
  },
  {
    id: 22,
    name: 'Healthcare',
    slug: 'healthcare',
  },
  {
    id: 23,
    name: 'Media and Entertainment',
    slug: 'media-and-entertainment',
  },
  {
    id: 24,
    name: 'Privacy and Security',
    slug: 'privacy-and-security',
  },
  {
    id: 25,
    name: 'Real Estate',
    slug: 'real-estate',
  },
];

export const BUSINESS_MODE = [
  {
    id: 1,
    name: 'VC',
    slug: 'vc',
  },
  {
    id: 2,
    name: 'Corporate VB',
    slug: 'corporate-vb',
  },
  {
    id: 3,
    name: 'Corporate VC',
    slug: 'corporate-vc',
  },
  {
    id: 7,
    name: 'Venture Directories',
    slug: 'venture-directories',
  },
  {
    id: 5,
    name: 'Operator VB',
    slug: 'operator-vb',
  },
  {
    id: 6,
    name: 'Innovation Lab',
    slug: 'innovation-lab',
  },
  {
    id: 8,
    name: 'Consulting VB',
    slug: 'consulting-vb',
  },
];

export const SEGMENTS = [
  {
    id: 1,
    name: 'Agnostic',
    slug: 'agnostic',
  },
  {
    id: 2,
    name: 'B2C',
    slug: 'b2-c',
  },
  {
    id: 3,
    name: 'B2B',
    slug: 'b2-b',
  },
  {
    id: 4,
    name: 'B2G',
    slug: 'b2-g',
  },
];

export const REGIONS = [
  {
    id: 1,
    name: 'Asia',
    slug: 'asia',
  },
  {
    id: 2,
    name: 'Europe',
    slug: 'europe',
  },
  {
    id: 3,
    name: 'North America',
    slug: 'north-america',
  },
  {
    id: 4,
    name: 'South America',
    slug: 'south-america',
  },
  {
    id: 5,
    name: 'Africa',
    slug: 'africa',
  },
  {
    id: 6,
    name: 'Mena',
    slug: 'mena',
  },
];

export const ACTIVELY_HIRING = [
  {
    id: 1,
    name: 'Engineers',
    slug: 'engineers',
  },
  {
    id: 2,
    name: 'Growth',
    slug: 'growth',
  },
  {
    id: 3,
    name: 'Legal',
    slug: 'legal',
  },
  {
    id: 4,
    name: 'Product Design',
    slug: 'product-design',
  },
  {
    id: 5,
    name: 'Data',
    slug: 'data',
  },
  {
    id: 6,
    name: 'Finance',
    slug: 'finance',
  },
  {
    id: 7,
    name: 'Founders/Senior/Executives',
    slug: 'founders-senior-executives',
  },
  {
    id: 7,
    name: 'Founders/Senior Executives',
    slug: 'founders-senior-executives',
  },
];

export const PARTNERSHIP_OPPORTUNITY = [
  { code: 1, name: 'Entrepreneurs-in-Residence', newCode: 1 },
  { code: 2, name: 'Strategic Partnerships', newCode: 2 },
  { code: 3, name: 'Proof of Concepts & Market Validation', newCode: 3 },
  { code: 4, name: 'Hiring', newCode: 4 },
  { code: 5, name: 'Business Support', newCode: 5 },
  { code: 6, name: 'Product & Design', newCode: 6 },
  { code: 7, name: 'Engineering', newCode: 7 },
  { code: 8, name: 'Strategic Advisory', newCode: 8 },
  { code: 9, name: 'Investment Dealflow', newCode: 9 },
  { code: 10, name: 'M&A Opportunities', newCode: 10 },
  { code: 11, name: 'Specialist Consultants', newCode: 11 },
  { code: 12, name: 'Specialist Mentors', newCode: 12 },
];

export const SERVICES_OFFERED = [
  { code: 1, name: 'Business Support', newCode: 1 },
  { code: 2, name: 'Product & Design', newCode: 2 },
  { code: 3, name: 'Engineering', newCode: 3 },
  { code: 4, name: 'Strategic Advisory', newCode: 4 },
  { code: 5, name: 'Strategic Partnerships', newCode: 5 },
  { code: 6, name: 'Market Validation', newCode: 6 },
  { code: 7, name: 'Idea Discovery', newCode: 7 },
  { code: 8, name: 'Specialist Mentors', newCode: 8 },
  { code: 9, name: 'Investing', newCode: 9 },
  { code: 10, name: 'M&A', newCode: 10 },
  { code: 11, name: 'Fundraising', newCode: 11 },
  { code: 12, name: 'Proprietary Data', newCode: 12 },
  { code: 13, name: 'Co-founders', newCode: 13 },
  { code: 14, name: 'Distribution', newCode: 14 },
];

export const APP_ENV = {
  PROD: 'production',
  DEV: 'development',
};

export const FILTER_BTN_GROUP = {
  status: [
    {
      title: 'All',
      value: '',
    },
    {
      title: 'Active',
      value: 'active',
    },
    {
      title: 'Inactive',
      value: 'inactive',
    },
  ],
  fundraising: [
    {
      title: 'All',
      value: '',
    },
    {
      title: 'Active',
      value: 'active',
    },
    {
      title: 'Inactive',
      value: 'inactive',
    },
  ],
  verify: [
    {
      title: 'All',
      value: '',
    },
    {
      title: 'Verified',
      value: 'active',
    },
    {
      title: 'Non-Verified',
      value: 'inactive',
    },
  ],
};
