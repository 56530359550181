import countries from 'i18n-iso-countries';
import defaultCountries from 'i18n-iso-countries/langs/en.json';
import type { StaticImageData } from 'next/image';

import defaultLogo from '@/assets/vb-map-default-logo.png';
import type AutocomplelteItemProps from '@/types/AutocompleteItem.type';
import type CommonProps from '@/types/Common.type';
import type LogoProps from '@/types/Logo.type';
import type SpecificLocationProps from '@/types/SpecificLocation.type';

import { APP_ENV } from './const';

countries.registerLocale(defaultCountries);

const getCompanyCover = (
  logo: LogoProps
): string | StaticImageData | undefined => {
  try {
    if (!logo) throw new Error('null');

    // return logo.formats.thumbnail.url;
    return logo.url;
  } catch (error) {
    return defaultLogo;
  }
};

const getCommonPropsDisplay = (
  commonProps?: CommonProps[],
  joinBy = ', '
): string => {
  if (!commonProps) return '';

  return commonProps.map((item) => item.name).join(joinBy);
};

const getCountryByCode = (countryCode?: string): string => {
  if (!countryCode) return 'n/a';
  const countryName = countries.getName(countryCode, 'en', {
    // there some country has array string as name
    // Example: ["United States of America", "United States", "USA"]
    // official: "United States of America", alias: "United States"
    // all: ["United States of America", "United States", "USA"]
    select: 'alias',
  });

  return countryName.toLowerCase();
};

const getLocationDisplay = (countryCode = '', city = ''): string => {
  if (countryCode?.length <= 0 && city?.length <= 0) return 'n/a';

  const countryName = getCountryByCode(countryCode);

  if (countryName === city?.toLowerCase()) {
    return countryName || 'n/a';
  }

  if (countryName !== 'n/a') {
    if (city) {
      return `${city} - ${countryName}`;
    }

    return countryName || '';
  }

  return city || '';
};

const getMainHQLocation = (locations: SpecificLocationProps[]): string => {
  const hqLocationIndex = locations.findIndex((ele) => ele.is_hq_location);
  if (hqLocationIndex > -1) {
    return getLocationDisplay(
      locations[hqLocationIndex]?.country,
      locations[hqLocationIndex]?.city
    );
  }

  return getLocationDisplay(locations[0]?.country, locations[0]?.city);
};

const getAllLocations = (
  locations?: SpecificLocationProps[],
  joinBy = ' | '
): string => {
  if (!locations) return '';

  return locations
    .map(({ country, city }) => {
      return getLocationDisplay(country, city);
    })
    .join(joinBy);
};

const getAllRegions = (
  locations?: SpecificLocationProps[],
  joinBy = ', '
): string => {
  if (!locations) return '';

  const regions = locations.map(({ region }) => region && region.name);
  return regions
    .filter((item, index) => item && regions.indexOf(item) === index)
    .join(joinBy);
};

const getCleanLink = (link: string): string => {
  if (!link) return '';

  if (link.includes('mailto:')) {
    return link;
  }

  const goodLink = link.replaceAll('www.', '');
  if (!goodLink.includes('https') && !goodLink.includes('http')) {
    return `https://${goodLink}`;
  }

  return goodLink;
};

const isValidLink = (link: string): boolean => {
  const url = getCleanLink(link);
  const regex = /(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})[/\w .-]*\/?/;
  return regex.test(url);
};

const getValidateEmail = (email: string) => {
  const isValidEmail = email.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  return isValidEmail ? email : undefined;
};

const isLive = () => process.env.NEXT_PUBLIC_DEBUG_MODE === APP_ENV.PROD;

const getCleanParams = (params: any): string => {
  const paramsObj = params;
  Object.keys(paramsObj).forEach((key) => {
    if (paramsObj[key] === '' || paramsObj[key] === undefined) {
      delete paramsObj[key];
    }
  });

  const paramsStr = new URLSearchParams(paramsObj).toString();
  const queryStr = paramsStr.length > 0 ? `?${paramsStr}` : '';

  return queryStr;
};

const getFilterStatusBool = (status = ''): boolean | undefined => {
  if (status.length > 0) {
    return status === 'active';
  }

  return undefined;
};

const getYearsOptions = (): AutocomplelteItemProps[] => {
  const pastYear = 50;
  const maxYear = new Date().getFullYear();
  const minYear = maxYear - pastYear;
  const yearsOptions: AutocomplelteItemProps[] = [];
  // eslint-disable-next-line no-plusplus
  for (let index = maxYear; index >= minYear; index--) {
    yearsOptions.push({
      id: index,
      title: index.toString(),
    });
  }

  return yearsOptions;
};

export {
  getAllLocations,
  getAllRegions,
  getCleanLink,
  getCleanParams,
  getCommonPropsDisplay,
  getCompanyCover,
  getFilterStatusBool,
  getLocationDisplay,
  getMainHQLocation,
  getValidateEmail,
  getYearsOptions,
  isLive,
  isValidLink,
};
